import "core-js/modules/es.array.map.js";
export default {
  name: "RolePermission",
  data: function data() {
    return {
      //
      infoRole: this.Ls.getParseToken('infoRole'),
      checkAll: false,
      isIndeterminate: true,
      // 用户信息
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      allList: [],
      // 所有功能组+功能项的内容
      roleHaveList: []
    };
  },
  watch: {},
  components: {},
  created: function created() {
    //获取数据
    this.initData();
  },
  methods: {
    handleCheckAllChange: function handleCheckAllChange(key) {
      if (this.allList[key].checkAll) {
        for (var i = 0; i < this.allList[key].functions.length; i++) {
          this.allList[key].checkedFunctions.push(this.allList[key].functions[i].id);
        }
      } else {
        this.allList[key].checkedFunctions = [];
      }
      this.allList[key].isIndeterminate = false;
    },
    handleCheckedFunctionsChange: function handleCheckedFunctionsChange(key) {
      var checkedCount = this.allList[key].checkedFunctions.length;
      this.allList[key].checkAll = checkedCount === this.allList[key].functions.length;
      this.allList[key].isIndeterminate = checkedCount > 0 && checkedCount < this.allList[key].functions.length;
    },
    // 返回函数
    goBack: function goBack() {
      this.$router.go(-1);
    },
    // 初始化数据
    initData: function initData() {
      var that = this;
      // 获取当前身份下拥有的
      this.service.axiosUtils({
        requestName: 'getRolePermissionList',
        data: {
          userId: that.userInfo.userId,
          roleId: that.userInfo.roleId,
          pageSize: that.pageSize,
          pageIndex: that.currentPage,
          rId: that.$route.query.rId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            that.roleHaveList = res.data[0];
            that.initAllData();
          } else {
            that.$toast.error(res.message);
          }
        }
      });
    },
    // 获取所有功能组+功能项目
    initAllData: function initAllData() {
      var that = this;
      this.service.axiosUtils({
        requestName: 'getAllRolePermissionList',
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            var tmpAllList = res.data;
            var haveList = that.roleHaveList.functions;
            tmpAllList.map(function (item) {
              item.checkAll = false;
              item.isIndeterminate = true;
              item.checkedFunctions = [];
              item.disabled = false;
            });
            for (var i = 0; i < tmpAllList.length; i++) {
              for (var j = 0; j < haveList.length; j++) {
                if (haveList[j].fgroupId === tmpAllList[i].id) {
                  tmpAllList[i].checkedFunctions.push(haveList[j].FunctionId);
                }
              }
              if (tmpAllList[i].checkedFunctions.length === tmpAllList[i].functions.length && tmpAllList[i].functions.length !== 0) {
                tmpAllList[i].checkAll = true;
              } else {
                tmpAllList[i].checkAll = false;
              }
              if (tmpAllList[i].functions.length === 0) {
                tmpAllList[i].disabled = true;
              } else {
                tmpAllList[i].disabled = false;
              }
            }
            that.allList = tmpAllList;
            console.log(that.allList);
          } else {
            that.$toast.error(res.message);
          }
        }
      });
    },
    // 更新权限
    renewAuth: function renewAuth() {
      var functionList = [];
      for (var i = 0; i < this.allList.length; i++) {
        if (this.allList[i].checkedFunctions.length !== 0) {
          for (var j = 0; j < this.allList[i].checkedFunctions.length; j++) {
            functionList.push(this.allList[i].checkedFunctions[j]);
          }
        }
      }

      //setRoleRight
      var that = this;
      // 获取当前身份下拥有的
      this.service.axiosUtils({
        requestName: 'setRoleRight',
        data: {
          rId: that.$route.query.rId,
          functionList: functionList
        },
        sucFn: function sucFn(res) {
          console.log(res.status);
          if (res.status === 1) {
            that.$toast.success(res.message);
            that.initData();
          } else {
            that.$toast.error(res.message);
          }
        }
      });
    }
  }
};